<!-- 优惠券管理 -->
<template>
	<div class="bg-white px-3 pt-1" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">

		<el-table border class="mt-3" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" size="mini">
			<el-table-column label="名称" prop="name" width="250"></el-table-column>
			<el-table-column align="center" prop="sycj" label="使用场景">
				<template slot-scope="scope">
					<div v-if="scope.row.sycj == 0" style="background: #03c200;color: #ffffff;">广告</div>
					<div v-else-if="scope.row.sycj == 1" style="background: #d50003;color: #ffffff;">邀请</div>
					<div v-else-if="scope.row.sycj == 2" style="background: #f58700;color: #ffffff;">抽奖</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="num" label="积分数量"></el-table-column>
			<el-table-column align="center" prop="dycs" label="限制次数"></el-table-column>
			<el-table-column align="center" prop="syzt" label="状态">
				<template slot-scope="scope">
					{{ scope.row.syzt }}
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" width="250">
				<template slot-scope="scope">
					<el-button-group>
						<el-button type="primary" size="mini" plain @click="openModel(scope)">修改</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination
					:current-page="page.current"
					:page-sizes="page.sizes"
					:page-size="page.size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="page.total"
			 	@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
				></el-pagination>
			</div>
		</el-footer>

		<!-- 新增/修改模态框 -->
		<el-dialog title="修改积分设置" :visible.sync="createModel" top="5vh">
			<!-- 表单内容 -->
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="名称" prop="name">
					<el-input v-model="form.name" placeholder="积分应用名称" size="mini" style="width: 30%;"></el-input>
				</el-form-item>
				
				<el-form-item label="使用场景" prop="sycj">
					<el-button type="primary" v-if="form.sycj == 0" size="mini">签到积分</el-button>
					<el-button type="primary" v-else-if="form.sycj == 1" size="mini">消费积分</el-button>
					<el-button type="primary" v-else-if="form.sycj == 2" size="mini">抽奖积分</el-button>
				</el-form-item>
				
				<el-form-item label="积分数量"><el-input-number size="mini" v-model="form.num" :min="0"></el-input-number></el-form-item>
				<el-form-item label="限制次数"><el-input-number size="mini" v-model="form.dycs" :min="0"></el-input-number></el-form-item>
				<el-form-item label="是否启用">
					<el-radio-group v-model="form.syzt" size="mini">
						<el-radio :label="1" border>启用</el-radio>
						<el-radio :label="0" border>禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button @click="createModel = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import common from '@/common/mixins/common.js'; // 引入 全局共公样式
import { mapState } from 'vuex'; // 引入 全局 公共数据
export default {

	mixins: [common], // 引入mixins里的common.js
	inject: ['app','layout'], // 注入layout.vue
	filters: {
		getStatus(row) {
			return formatStatus(row);
		}
	},
	data() {
		return {
			preUrl: 'jfsz',

			tableData: [],

			createModel: false,
			editIndex: -1,
			form: {
				name: '',
				type: 0,
				yhlx:0,
				types: 0,
				value: 0,
				total: 100,
				min_price: 0,
				start_time: '',
				end_time: '',
				status: 1,
				order: 50,
				dycs:0,
				desc: '',
				mamager_id: '', // 商家id
			},
		};
	},
	computed: {
		...mapState({
			user: state => state.user.user,
		})
	},
	methods: {
		getListUrl() {
			return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}`;
		},
		getListResult(e) {
			this.tableData = e.list;
			
		},
		getlist(){
			this.axios.get('/admin/jfsz/1', {
				token: true
			}).then(res => {
				this.tableData = res.data.data.list
			})
		},
		// 打开模态框
		openModel(e = false) {
			const r = e.row;
			this.form = {
				name: r.name,
				sycj: r.sycj,
				num: r.num,
				dycs: r.dycs,
				syzt:r.syzt,
				id:r.id
			};
			this.editIndex = e.$index;
			// 打开dialog
			this.createModel = true;
		},
		// 添加规格
		submit() {
			this.axios.post('/admin/jfsz/'+this.form.id, this.form, {
				token: true
			}).then(res => {
				this.$message({
					type: "success",
					message: "操作成功"
				})
				this.getlist()
			})
			// 关闭模态框
			this.createModel = false;
		}
	}
};
</script>

<style>
.sku-list-item > i {
	display: none;
	cursor: pointer;
}
.sku-list-item:hover {
	background-color: #f4f4f4;
}
.sku-list-item:hover > font {
	display: none;
}
.sku-list-item:hover > i {
	display: inline-block;
}
.el-form-item{
	margin-bottom: 0;
}
</style>
